.custom-progress-bar1 .progress-bar {
  background-color: #ff7255;
}
.custom-progress-bar2 .progress-bar {
  background-color: #ff7255;
}
.custom-progress-bar3 .progress-bar {
  background-color: #ff7255;
}
.custom-progress-bar4 .progress-bar {
  background-color: #ff7255;
}
.custom-progress-bar5 .progress-bar {
  background-color: #ff7255;
}
.custom-progress-bar6 .progress-bar {
  background-color: #ff7255;
}
.custom-progress-bar7 .progress-bar {
  background-color: #ff7255;
}
.custom-progress-bar8 .progress-bar {
  background-color: #ff7255;
}
.custom-progress-bar9 .progress-bar {
  background-color: #ff7255;
}
.custom-progress-bar10 .progress-bar {
  background-color: #ff7255;
}
.custom-progress-bar11 .progress-bar {
  background-color: #ff7255;
}
.custom-progress-bar12 .progress-bar {
  background-color: #ff7255;
}
.custom-progress-bar13 .progress-bar {
  background-color: #ff7255;
}
