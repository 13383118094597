.underline {
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.15em;

  display: inline-block;
  padding: 15px 20px;
  position: relative;
}
.underline:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: #fff; /*to change colour of underline on hover*/
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
.underline:hover:after {
  width: 100%;
  left: 0;
}

.selected:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 0;
  position: absolute;
  background: #eca8b4; /*to change colour of underline */
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 100%;
}

.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.fixedtbl {
  table-layout: fixed;
}
/* .fixedtbl td {
  overflow: auto;
} */

.wrapper {
  overflow-x: auto;
}

.progress-custom {
  color: black;
}

.fixedtbl th {
  overflow-wrap: break-word;
}
html {
  scroll-snap-type: y mandatory;
}
body {
	background: linear-gradient(-45deg, #7028e4AA, #009efdAA, #cd9cf2AA, #2575fcAA);
	background-size: 500% 500%;
	animation: gradient 12s ease infinite;
	height: 100vh;
  scroll-snap-type: y mandatory;
  overflow-y: auto;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

section {
  height: 100vh;
  padding: 15px;
  scroll-snap-align: start;
}


.fixedtbl {
  table-layout: fixed;
  margin:10
}
/* .fixedtbl td {
  overflow: auto;
} */

.wrapper {
  overflow: hidden;
}

.SummaryTable {
  /* overflow-x: scroll; */
  height: 90vh;
  background-color: #ffffff88;
  padding: 10;
  margin: 10;
  border-radius: 15;
}

.progress-custom {
  color: black;
}

.fixedtbl th {
  overflow-wrap: break-word;
}

.sc5::-webkit-scrollbar {
  width: 15px;
  height: 15px;
}
.sc5::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.1);
}
.sc5::-webkit-scrollbar-thumb {
  background-image: linear-gradient(45deg, #00aeff, #a68eff);
  border-radius: 10px;
	-webkit-box-shadow: rgba(0,0,0,.12) 0 3px 13px 1px;
}

.continuous-4 {
  width: 120px;
  height: 22px;
  border-radius: 40px;
  color: #514b82;
  border: 2px solid;
  position: relative;
  overflow: hidden;
}

.continuous-4::before {
  content: "";
  position: absolute;
  margin: 2px;
  width: 14px;
  top: 0;
  bottom: 0;
  left: -20px;
  border-radius: inherit;
  background: currentColor;
  box-shadow: -10px 0 12px 3px currentColor;
  clip-path: polygon(0 5%, 100% 0,100% 100%,0 95%,-30px 50%);
  animation: ct4 1s infinite linear;
}

@keyframes ct4 {
  100% {left: calc(100% + 20px)}
}

.icon {
  background-color: rgba(200,200,200);
  padding: 2px 5px;
  width: fit-content;
}



